import "styles/pages/page-policy-cookies.scss";

import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

const CookiesPolicy = ({ pageContext }) => {
	const cookiesPolicy = pageContext.data;
	return (
		<Layout headerColor="#0260DB">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/single-blog-bg-main.jpg").default}
			/>
			<div className="section-policy-cookies">
				<div className="container">
					<h2 className="section-title">{pageContext.title}</h2>
					<div
						dangerouslySetInnerHTML={{
							__html: cookiesPolicy.content,
						}}
					></div>
				</div>
			</div>
		</Layout>
	);
};

export default CookiesPolicy;
